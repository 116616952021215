import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Messaging App Template | Messaging App UI Design | Uizard"
    category="Mobile App Templates"
    metaDescription="#Checked is a mobile messaging app design template that sets the stage for you to design a powerful yet easy-to-use communication hub."
    url="/templates/mobile-app-templates/messaging-app/"
    description="
    h2:Introducing #Checked: A mobile messaging app design template
    <br/>
    #Checked is Uizard's new instant messaging app design template, created from the ground up to provide you with a simple template for a chat UI, including a chat box design. Our chat app design template comes with everything you could possible need to <a:https://uizard.io/prototyping/>rapidly prototype</a> your own messaging app design. 
    <br/>
    h3:A mobile messaging app design with clean and crisp features
    <br/>
    #Checked helps set the stage for you to design a powerful yet easy-to-use communication hub. With a unique color scheme that is sure to stand out and a custom design that makes it simple to follow conversations, this chat box design template — with a modern and crisp UI design — empowers you to design your own messaging application in minutes.
    <br/>
    h3:Design your mobile messaging app collaboratively
    <br/>
    As with all our <a:https://uizard.io/templates/>UI design templates</a>, #Checked is aimed at helping individuals and teams design great messaging experiences. Created with a focus on making the simplest of interactions fun, easy, and stylish, you can use our chat UI template as a base to fast track your project flow.
    "
    pages={[
      "A welcome screen",
      "A home screen",
      "A new message screen",
      "A chatbox design",
      "A settings screen",
      "A profile screen",
      "And more!",
    ]}
    projectCode="XXbLarg64WTZ5GvlmBmJ"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of instant messsaging movile app: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of instant messsaging movile app: chat"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of instant messsaging movile app: messages"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of instant messsaging movile app: settings screen"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of instant messsaging movile app template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/messaging-app/Messaging-App-Cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/messaging-app/Messaging-App-Chat.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/messaging-app/Messaging-App-Chat2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/messaging-app/Messaging-App-Settings.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/messaging-app/Messaging-App-Summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
